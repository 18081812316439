// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-opened {
    animation: scale-back 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.popup-closed {
    animation: scale-forward 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.popup-container {
    pointer-events: none;
}

.popup-container .popup-backdrop {
    opacity: 0;
}

.popup-container.in {
    transform: scale(1);
    pointer-events: all;
}

.popup-container.in .popup-backdrop {
    background: rgba(0, 0, 0, 0);
    opacity: 1;
    animation: fade-in 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.popup-container .popup-backdrop .popup {
    max-height: calc(100vh - 4rem);
}

.popup-container.in .popup-backdrop .popup {
    opacity: 0;
    animation: scale-up 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.popup-container.out {
    animation: quick-scale-down 0s 0.5s linear forwards;
    pointer-events: none;
}

.popup-container.out .popup-backdrop {
    opacity: 1;
    animation: fade-out 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.popup-container.out .popup-backdrop .popup {
    animation: scale-down 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
`, "",{"version":3,"sources":["webpack://./src/components/Popup/style.css"],"names":[],"mappings":"AAAA;IACI,sEAAsE;AAC1E;;AAEA;IACI,yEAAyE;AAC7E;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;IAC5B,UAAU;IACV,mEAAmE;AACvE;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,oEAAoE;AACxE;;AAEA;IACI,mDAAmD;IACnD,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,oEAAoE;AACxE;;AACA;IACI,sEAAsE;AAC1E","sourcesContent":[".popup-opened {\n    animation: scale-back 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;\n}\n\n.popup-closed {\n    animation: scale-forward 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;\n}\n\n.popup-container {\n    pointer-events: none;\n}\n\n.popup-container .popup-backdrop {\n    opacity: 0;\n}\n\n.popup-container.in {\n    transform: scale(1);\n    pointer-events: all;\n}\n\n.popup-container.in .popup-backdrop {\n    background: rgba(0, 0, 0, 0);\n    opacity: 1;\n    animation: fade-in 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;\n}\n\n.popup-container .popup-backdrop .popup {\n    max-height: calc(100vh - 4rem);\n}\n\n.popup-container.in .popup-backdrop .popup {\n    opacity: 0;\n    animation: scale-up 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;\n}\n\n.popup-container.out {\n    animation: quick-scale-down 0s 0.5s linear forwards;\n    pointer-events: none;\n}\n\n.popup-container.out .popup-backdrop {\n    opacity: 1;\n    animation: fade-out 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;\n}\n.popup-container.out .popup-backdrop .popup {\n    animation: scale-down 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
