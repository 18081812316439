/* eslint-disable react/jsx-no-literals */
import React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie, setCookie } from '../../utils/cookies';
import openEditedFlow from '../../utils/openEditedFlow';

const OnboardingFunction = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  if (!user) {
    return null;
  }
  const { client } = user;

  return (
    <button
      id="onboarding-button"
      type="button"
      onClick={() => {
        axios
          .post('/flows', {
            name: 'My first flow',
          })
          .then(response => {
            const flow = {
              ...response.data,
              domain: {
                domain: client?.app_domain,
              },
            };
            openEditedFlow({
              flow,
              dispatch,
            });

            // CLEANUP: these next two lines can be removed once the extension is updated
            const userGuide = getCookie('bb_userguide');
            setCookie('bb_userguide', userGuide + 1);

            // Try to notify the extension
            try {
              window.postMessage({ type: 'BONBOARDING_START_ONBOARDING' }, '*');
            } catch (e) {
              // Extension not installed, we don't need to do anything
            }
          })
          .catch(error => {
            console.error(error);
          });
      }}
      className="w-0 h-0"
    >
      x
    </button>
  );
};

export default OnboardingFunction;
