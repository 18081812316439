// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-timeline ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.info-timeline ul li span.timeline-circle {
    position: relative;
    border: 4px solid #46344e;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    background-color: #fff;
    z-index: 2;
    display: inline-block;
}

.info-timeline ul li:not(:first-of-type) span.timeline-circle::before {
    position: absolute;
    border: 1.5px solid #9d8d8f;
    width: 0;
    height: 50px;
    display: block;
    content: '';
    left: 50%;
    z-index: 1;
    top: -56px;
    margin-left: -1.5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/UserModal/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,QAAQ;IACR,YAAY;IACZ,cAAc;IACd,WAAW;IACX,SAAS;IACT,UAAU;IACV,UAAU;IACV,mBAAmB;AACvB","sourcesContent":[".info-timeline ul {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n}\n\n.info-timeline ul li span.timeline-circle {\n    position: relative;\n    border: 4px solid #46344e;\n    border-radius: 100%;\n    width: 12px;\n    height: 12px;\n    background-color: #fff;\n    z-index: 2;\n    display: inline-block;\n}\n\n.info-timeline ul li:not(:first-of-type) span.timeline-circle::before {\n    position: absolute;\n    border: 1.5px solid #9d8d8f;\n    width: 0;\n    height: 50px;\n    display: block;\n    content: '';\n    left: 50%;\n    z-index: 1;\n    top: -56px;\n    margin-left: -1.5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
